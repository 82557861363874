$(function () {
    var $stickybar = $('.ui.stickybar'),
        $navList = $stickybar.find('.nav.list .list'),
        $navIndicator = $stickybar.find('.nav.indicator'),
        $navAI = $navList.find('.ai.item'),
        $navFeatures = $navList.find('.features.item'),
        $navHowitworks = $navList.find('.howitworks.item');


    var lastScrollTop = 0;

    /**
     * Hide stickybar
     */
    function hideStickyBar() {
        $stickybar.removeClass('shown');
    }

    /**
     * Check if screensize fall into mobile
     * @return boolean Is mobile screen
     */
    function isMobile() {
        return $(window).innerWidth() <= 768;
    }

    /**
     * Show stickybar
     */
    function showStickyBar() {
        $stickybar.addClass('shown');
    }

    /**
     * Change active state for the stickybar nav items
     * @param  String item stickybar nav item
     */
    function toggleStickbarNavItem(item) {
        var _offset = 0,
            _padding = 32,
            _width = 0.1;

        $navList.children().removeClass('active');

        if (item == 'ai') {
            $navAI.addClass('active');
            _width = $navAI.width() + _padding;
        }
        else if (item == 'features') {
            $navFeatures.addClass('active');
            _width = $navFeatures.width() + _padding;
        }
        else if (item == 'howitworks') {
            $navHowitworks.addClass('active');
            _width = $navHowitworks.width() + _padding;
        }

        switch (item) {
            case 'ai':
                _offset += $navFeatures.width() + _padding;

            case 'features':
                _offset += $navHowitworks.width() + _padding;

            case 'howitworks':
                break;

            default:
                break;
        }

        $navIndicator.css({ 'right': _offset, 'width': _width });
    }

    /**
     * Event function on window scrolled
     */
    $(window).scroll(function () {
        var _scrollTop = $(this).scrollTop();

        if (isMobile()) {
        }

        else {
            // Downward scroll
            if (_scrollTop > lastScrollTop) {

                if (_scrollTop > $('.ui.heroimage').height()) {
                    showStickyBar();
                }
            }
            // Upward scroll
            else {
                if (_scrollTop <= $('.ui.heroimage').height()) {
                    hideStickyBar();
                }
            }

            /* Stickybar */
            if (_scrollTop >= $('#ai').offset().top) {
                if (_scrollTop >= $('#features').offset().top) {
                    if (_scrollTop >= $('#how-it-works').offset().top) {
                        if (_scrollTop >= $('#how-it-works').offset().top + $('#how-it-works').height()) {
                            toggleStickbarNavItem();
                        }
                        else {
                            toggleStickbarNavItem('howitworks');
                        }
                    }
                    else {
                        toggleStickbarNavItem('features');
                    }
                }
                else {
                    toggleStickbarNavItem('ai');
                }
            }
        }

        lastScrollTop = _scrollTop;
    });

    /**
     * Event function on window resized
     */
    $(window).resize(function () {
        if ($(this).innerWidth() <= 768) {
            hideStickyBar();
        }
    });

    hideStickyBar();
});